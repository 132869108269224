<template>
  <div>
    <Header class="fixed-box" :title="'亚洲分期产品详情'" :back="true"/>
    <div v-for="i in 13" :key="i" class="img-box">
     <img :src="require('@/assets/img/detail/'+i+'.jpg')"/>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  methods:{
 
  }
}
</script>

<style lang="scss" scoped>
.img-box{
  margin-top:3.5rem;
  img{
    width: 100%;
  }
}
.fixed-box{
  position: fixed !important;
  width: 100%;
  top:0

}
</style>