var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header", {
        staticClass: "fixed-box",
        attrs: { title: "亚洲分期产品详情", back: true },
      }),
      _vm._l(13, function (i) {
        return _c("div", { key: i, staticClass: "img-box" }, [
          _c("img", {
            attrs: { src: require("@/assets/img/detail/" + i + ".jpg") },
          }),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }